







import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import UiLoading from '@/components/ui/UiLoading.vue';
@Component({
  components: {
    UiLoading,
  },
})
export default class OnRamp extends BaseVue {
  public isLoading = true;
  public embedUrl = 'https://projectplan.bitwave.io/';
}
